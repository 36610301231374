import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { checkRoute } from "../../utils/checkroute";
// All modal for adding , delete , editing
import AddArtists from "./addArtists";
import UpdateArtists from "./updateArtists";
import Delete_Artist from "./deleteArtists"
import PublishUnpublish from "./publishUnpublish";
import { fetchAllArtists } from "../../actions/artists/getAllArtists"
import { fetchArtistById } from "../../actions/artists/getArtistById"
import { connect } from "react-redux";
import Pagination from "react-js-pagination";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactTooltip from "react-tooltip";
import '../../styles/css/pagination.css';

function Artists(props) {
  const history = useHistory();
  const matches = useMediaQuery("(max-width:400px)");
  const [artistId, setArtistId] = useState("")

  const [paginationvalues, setPaginationValues] = useState({});

  const [showingpage, setShowingpage] = useState({
    from: 1,
    to: 2,
    sortingorder: "desc",
    search: null,
    columnname: "",
  });

  const {
    loading,
    error,
    message,
    fetchAllArtistsData,
    fetchArtistDetailsById,
    getAllArtistData,
    getArtistDataById
  } = props;

  useEffect(() => {
    localStorage.setItem("View_Name", "Artists");
    checkRoute();

  }, [getAllArtistData]);

  useEffect(() => { fetchAllArtistsData() }, [])


  const handleContactDetailView = async (id) => {
    history.push({
      pathname: "/propertyDeatils",
      state: { contactid: id },
    });
  };

  const [data, setData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
    setCurrentPage(1); // Reset page when search keyword changes
  };
  // artistUser?.phoneNumber
  // Filter the data based on the search keyword

  const filteredData = Object.entries(getAllArtistData).length !== 0 ? getAllArtistData?.filter(item =>
    item?.profileName?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    item?.artistUser?.phoneNumber?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    item?.artistUser?.userType?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    item?.genres?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
    item?.baseLocationUrl?.toLowerCase().includes(searchKeyword.toLowerCase())
  ) : []

  const sortedData = filteredData?.slice().sort((a, b) => {
    const aValue = (sortColumn == "profileName" ? a.profileName : sortColumn == "userType" ? a?.artistUser?.userType : sortColumn == "genres" ? a.genres : sortColumn == "baseLocationUrl" ? a.baseLocationUrl : sortColumn == "phoneNumber" ? a?.artistUser?.phoneNumber : a[sortColumn])
    const bValue = (sortColumn == "profileName" ? b.profileName : sortColumn == "userType" ? b?.artistUser?.userType : sortColumn == "genres" ? b.genres : sortColumn == "baseLocationUrl" ? b.baseLocationUrl : sortColumn == "phoneNumber" ? b?.artistUser?.phoneNumber : b[sortColumn]);
    if (sortDirection === 'asc') {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    } else {
      return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
    }
  });

  // Calculate the total number of pages
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  // Get the current page of items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle column sorting
  const handleColumnSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prevSortDirection) =>
        prevSortDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  return !loading ? (
    <>
      <div className="container-fluid">
        {/* <!-- Page Heading --> */}
        <div className="page-breadcrumb">
          <div className="row">
            <div className="col-5 align-self-center">
              <h4 className="page-title">Artists</h4>
              <div className="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-1">
                    <li className="breadcrumb-item">
                      <a
                        className="breadcrumb-link"
                        onClick={() => history.push("/Artists")}
                        href={() => false}
                      >
                        Home
                      </a>
                    </li>
                    <li
                      className="breadcrumb-item active"
                      aria-current="page"
                    >
                      Artists
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="col-7 align-self-center">
              <div className="d-flex no-block justify-content-end align-items-center">
                <div className="m-r-10">
                  <div className="lastmonth"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-md-flex  align-items-start bg-light p-2 mb-3">
                  <div className="col-lg-4">
                    <div class="search-box">
                      <input class="search-input" type="text" placeholder="Search.." value={searchKeyword}
                        onChange={handleSearchChange} />
                      <button class="search-btn"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                  <div className="col-lg-4 pt-2"></div>
                  <div className="col-lg-4 pt-2 text-right">
                    {" "}
                    <a
                      href={() => false}
                      className="btn btn-primary "
                      data-toggle="modal"
                      data-target="#createCustomerModal"
                    >
                      Add Artist
                    </a>{" "}
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {/* col for id of the artist */}
                        <th onClick={() => handleColumnSort('id')}>
                          <div>
                            ArtistId
                          </div>

                        </th>
                        <th onClick={() => handleColumnSort('profileName')}>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                            id="column"
                          >
                            Name {sortColumn === 'profileName' && (
                              <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                            )}


                          </div>
                        </th>
                        {/* <th onClick={() => handleColumnSort('userType')}>
                            <div
                              className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                              id="column"
                            >
                              Artist Type {sortColumn === 'userType' && (
                                <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                              )}


                            </div>
                          </th> */}
                        <th onClick={() => handleColumnSort('genres')}>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                            id="column"
                          >
                            Genre {sortColumn === 'genres' && (
                              <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                            )}


                          </div>
                        </th>


                        <th onClick={() => handleColumnSort('baseLocationUrl')}>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                            id="column"
                          >
                            Base Location {sortColumn === 'baseLocationUrl' && (
                              <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                            )}


                          </div>
                        </th>
                        <th onClick={() => handleColumnSort('phoneNumber')}>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                            id="column"
                          >
                            Phone No. {sortColumn === 'phoneNumber' && (
                              <span>{sortDirection === 'asc' ? '▲' : '▼'}</span>
                            )}


                          </div>
                        </th>

                        <th>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                            id="column"
                          >
                            <div>Published</div>
                          </div>
                        </th>
                        <th>
                          <div
                            className="d-flex flex-row justify-content-start align-items-center flex-nowrap"
                            id="column"
                          >
                            <div>Publish</div>
                          </div>
                        </th>



                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems !== null && currentItems !== undefined && Object.entries(currentItems).length !== 0 ? (
                        currentItems.map((item) => {
                          return (
                            <tr>
                              {/* <td>
                                  {item.backgroundPhotoPath !== "" ? (
                                    <img
                                      src={
                                        // process.env.REACT_APP_API_KEY_LAYOUT +
                                        item.backgroundPhotoPath
                                      }
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src="img/noimage.png"
                                      className="rounded-circle noimageUser"
                                      alt=""
                                    />
                                  )}
                                </td> */}

                              <td>
                                <a
                                  className="breadcrumb-link"
                                  href={`/Artists_Details?artistId=${item.artistId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent default link behavior
                                    window.open(`/Artists_Details?artistId=${item.artistId}`, '_blank');
                                    localStorage.setItem("artistId", item.artistId);
                                  }}
                                >
                                  {item.artistId}
                                </a>
                              </td>
                              <td>
                              <a
                                  className="breadcrumb-link"
                                  href={`/Artists_Details?artistId=${item.artistId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent default link behavior
                                    window.open(`/Artists_Details?artistId=${item.artistId}`, '_blank');
                                    localStorage.setItem("artistId", item.artistId);
                                  }}
                                >
                                  {item.profileName}
                                </a>
                              </td>
                              {/* <td> {item.profileName}</td> */}
                              {/* <td>{item?.artistUser?.userType}</td> */}
                              <td>{item.artistUser?.email}</td>

                              <td>{item?.baseLocation?.city},{item?.baseLocation?.state}</td>
                              <td>{item?.artistUser?.phoneNumber}</td>
                              <td style={{ color: item.published === true ? "green" : "red" }}>{item.published.toString().toUpperCase()}</td>
                              <td >
                                <a
                                  href={() => false}
                                  className="cursor"
                                  data-toggle="modal"
                                  data-target="#PublishorunpublishArtist"
                                  onClick={() => fetchArtistDetailsById(item.artistId)}
                                >
                                  <button type="button" class="btn btn-info">Publish</button>
                                  {/* <i className="fa fa-edit"></i> */}
                                </a>{" "}
                              </td>
                              <td>
                                <a
                                  href={() => false}
                                  className="cursor"
                                  data-toggle="modal"
                                  data-target="#updateArtist"
                                  onClick={() => fetchArtistDetailsById(item.artistId)}
                                >
                                  <i className="fa fa-edit"></i>
                                </a>{" "}
                                <a
                                  href={() => false}
                                  className="cursor"
                                  data-toggle="modal"
                                  data-target="#deleteArtistModal"
                                  onClick={() => {
                                    setArtistId(item.artistId);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </td>


                            </tr>
                          );
                        })
                      ) : getAllArtistData.length === 0 ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <div>
                              <p>
                                <strong style={{ color: "grey" }}>
                                  No records found
                                </strong>
                              </p>
                            </div>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      ) : (
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <div className="loading1"></div>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <button
                        key={i + 1}
                        onClick={() => handlePageChange(i + 1)}
                        disabled={currentPage === i + 1}
                        style={{ borderRadius: "40px", width: "40px", height: "35px" }}
                      >
                        {i + 1}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddArtists />
      <UpdateArtists
        getArtistDataById={getArtistDataById}
      />
      <PublishUnpublish
        getArtistDataById={getArtistDataById}
      />
      <Delete_Artist artistId={artistId} />

      <ReactTooltip />
    </>
  ) : (
    <div className="cover-spin"></div>
  );
}

const mapStateToProps = (state, props) => {

  return {
    getAllArtistData: state.getAllArtist.data,
    loading: state.getAllArtist.loading,
    getArtistDataById: state.getArtistById.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    fetchAllArtistsData: () => dispatch(fetchAllArtists()),
    fetchArtistDetailsById: (id) => dispatch(fetchArtistById(id))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Artists);
